import { useState } from "react";
import { useDispatch } from "react-redux";
import { TextInput } from "react-materialize";

import api from "../functions/api";

import { setUser } from "../redux/slices";

const Login = () => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const dispatch = useDispatch();

  const login = async () => {
    try {
      const { _id } = await api.post("/login-agent", {
        username,
        password,
      });

      dispatch(setUser(_id));
    } catch (err) {
      console.log("🚀 ~ login ~ err:", err);
    }
  };

  return (
    <div className="login">
      <main className="card">
        <TextInput
          label="Username"
          onChange={(e) => setUsername(e.currentTarget.value)}
          type="password"
        />
        <TextInput
          label="Password"
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <button
          className="btn"
          disabled={!username || !password}
          onClick={login}
        >
          LOGIN
        </button>
      </main>
    </div>
  );
};

export default Login;
