import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Login from "./components/login";
import Icon from "./components/icon";

import Users from "./pages/app/users";

import { setPin, setUser, setWallet } from "./redux/slices";
import PinLogin from "./components/pinLogin";
import api from "./functions/api";
import WithDraws from "./pages/app/withdraws";
import Recharges from "./pages/app/recharges";

const Navigations = () => {
  const user = useSelector((s) => s.user.value);
  const pin = useSelector((s) => s.pin.value);
  const wallet = useSelector((s) => s.wallet.value);

  const dispatch = useDispatch();

  const navs = [
    { title: "Users", path: "/users", icon: "group" },
    { title: "Recharges", path: "/recharges", icon: "monetization_on" },
    { title: "Withdraws", path: "/withdraws", icon: "currency_rupee" },
  ];

  const logout = () => {
    const confirm = window.confirm("Are you sure you want to logout?");

    if (!confirm) return;

    dispatch(setPin(false));
    dispatch(setUser(null));
  };

  const getAgent = async () => {
    try {
      const [{ wallet }] = await api.get("/agent/" + user);
      dispatch(setWallet(wallet));
    } catch (err) {
      console.log("🚀 ~ getAgent ~ err:", err);
    }
  };

  useEffect(() => {
    dispatch(setPin(false));
    window.addEventListener("unload", () => {
      dispatch(setPin(false));
    });
    getAgent();
  }, []);

  return (
    <BrowserRouter>
      {!user ? (
        <Login />
      ) : (
        <div className="cont">
          {!pin ? (
            <PinLogin />
          ) : (
            <>
              <div className="side-bar">
                <div className="navs">
                  {navs.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.path}
                      className={({ isActive }) => isActive && "nav-active"}
                    >
                      <Icon name={item.icon} />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
                <div>
                  <span className="wallet">Wallet: {wallet}</span>
                </div>
                <button className="btn red logout" onClick={logout}>
                  logout
                </button>
              </div>
              <div className="routes">
                <Routes>
                  <Route path="/users" element={<Users />} />
                  <Route path="/withdraws" element={<WithDraws />} />
                  <Route path="/recharges" element={<Recharges />} />
                </Routes>
              </div>
            </>
          )}
        </div>
      )}
    </BrowserRouter>
  );
};

export default Navigations;
