import { persistStore, persistReducer } from "redux-persist";
import { pinReducer, userReducer, walletReducer } from "./slices";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "user",
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_KEY,
      onError: function (error) {
        console.log("🚀 ~ redux error:", error);
      },
    }),
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  pin: pinReducer,
  wallet: walletReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
      // Redux persist
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat([]),
});

export const pStore = persistStore(store);
