import { useEffect, useState } from "react";
import moment from "moment";
import { TextInput } from "react-materialize";

import Paginations from "../../components/paginations";
import Model from "../../components/model";

import api from "../../functions/api";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../../redux/slices";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [mobNo, setMobNo] = useState(null);
  const [sm, setSm] = useState(false);
  const [amount, setAmount] = useState(null);
  const [_id, setId] = useState(null);

  const user = useSelector((s) => s.user.value);
  const wallet = useSelector((s) => s.wallet.value);

  const dispatch = useDispatch();

  const getUsers = async (page, search, id) => {
    const res = await api.get(
      `/users?page=${page || 1}&numOfItem=${10}&mobNo=${search || null}&id=${
        id ? search : ""
      }&agent=${user}`
    );
    setUsers(res);
  };

  const addUser = async (e) => {
    e.preventDefault();
    if (!mobNo || !mobNo.match(/^[6-9]\d{9}$/))
      return window.M.toast({ html: "Invalid Mobile Number" });

    try {
      await api.post("/add-user", { mobNo, agent: user });
      window.M.toast({ html: "User created" });
      setShowModel(false);
      getUsers();
      setMobNo(null);
    } catch (err) {
      console.log("🚀 ~ addUser ~ err:", err);
    }
  };

  const addBalance = async (e) => {
    e.preventDefault();
    if (!amount || !amount.match(/^\d*\.?\d+$/))
      return window.M.toast({ html: "Invalid Balance" });

    try {
      await api.post("/add-balance", { user: _id, agent: user, amount });
      window.M.toast({ html: "Balance added" });
      dispatch(setWallet(parseFloat(wallet) - parseFloat(amount)));
      setSm(false);
      setAmount(null);
      getUsers();
    } catch (err) {
      console.log("🚀 ~ addUser ~ err:", err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="users">
      <Model show={showModel} onHide={setShowModel}>
        <form className="card" onSubmit={addUser}>
          <TextInput
            label="Mobile Number"
            value={mobNo}
            onChange={(e) => setMobNo(e.currentTarget.value)}
            validate
          />
          <button className="btn">SAVE</button>
        </form>
      </Model>

      <Model show={sm} onHide={setSm}>
        <form className="card" onSubmit={addUser}>
          <TextInput
            label="Balance"
            value={amount}
            onChange={(e) => setAmount(e.currentTarget.value)}
            validate
          />
          <button className="btn" onClick={addBalance}>
            add
          </button>
        </form>
      </Model>
      <button className="btn" onClick={() => setShowModel(true)}>
        Add User
      </button>
      <TextInput
        label="Search User by Mobile Number"
        onChange={(text) => getUsers(1, text.currentTarget.value)}
      />
      <TextInput
        label="Search User by ID"
        onChange={(text) => getUsers(1, text.currentTarget.value, true)}
      />
      <table>
        <tbody>
          <tr>
            <th>Sr. No.</th>
            <th>ID</th>
            <th>Mobile Number</th>
            <th>Wallet</th>
            <th>Add Balance</th>
            <th>Joined Time</th>
          </tr>
          {users.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item._id}</td>
              <td>{item.mobNo}</td>
              <td>{item.wallet || 0}</td>
              <td>
                <button
                  className="btn"
                  onClick={() => {
                    setSm(true);
                    setId(item._id);
                  }}
                >
                  Add balance
                </button>
              </td>
              <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginations onChange={getUsers} />
    </div>
  );
};

export default Users;
