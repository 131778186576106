import { useState, useEffect } from "react";
import { TextInput } from "react-materialize";
import moment from "moment";

import TabBar from "../../components/tabBar";
import Model from "../../components/model";

import Paginations from "../../components/paginations";

import api from "../../functions/api";
import { useSelector } from "react-redux";

const Recharges = () => {
  const RechargeList = ({ status }) => {
    const [data, setData] = useState([]);
    const [date, setDate] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const user = useSelector((s) => s.user.value);

    const filters = [
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "This Week", value: "thisWeek" },
      { label: "This Month", value: "thisMonth" },
    ];

    const getData = async (page, search, id) => {
      const res = await api.get(
        `/agent-recharges?page=${page || 1}&numOfItem=${10}&status=${
          status || ""
        }&id=${id ? search : ""}&date=${date || ""}&from=${from || ""}&to=${
          to || ""
        }&agent=${user}`
      );
      setData(res);
    };

    useEffect(() => {
      getData();
    }, [date, to, from, status]);

    return (
      <div className="recharge-list">
        <TextInput
          label="Search by User ID"
          onChange={(text) => getData(1, text.currentTarget.value, true)}
        />
        <div className="filteres">
          {filters.map((item) => (
            <div
              className={`filter ${
                item.value === date && item.value && "active"
              }`}
              onClick={() => setDate(item.value)}
            >
              {item.label}
            </div>
          ))}

          <div className="date-picker">
            <TextInput
              label="From"
              type="date"
              value={from}
              onChange={(e) => {
                setFrom(e.currentTarget.value);
                setDate(null);
              }}
            />
          </div>

          <div className="date-picker">
            <TextInput
              label="To"
              type="date"
              value={to}
              onChange={(e) => {
                setTo(e.currentTarget.value);
                setDate(null);
              }}
            />
          </div>

          <button
            className="btn"
            onClick={() => {
              setDate(null);
              setFrom(null);
              setTo(null);
            }}
            style={{ marginLeft: 30 }}
          >
            Clear
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              <th>Sr. No. </th>
              <th>ID</th>
              <th>User ID</th>
              <th>Amount</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.amount}</td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  return (
    <div className="recharges">
      <RechargeList />
    </div>
  );
};

export default Recharges;
